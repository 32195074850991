<div class="m-4">
    <div class="col-12 d-flex justify-content-between">
        <h3>{{'labels.template' | translate}}</h3>
        <mat-icon class="icon-actions minor-highlight" matDialogClose>cancel</mat-icon>
    </div>
    <hr class="col">

    <div class="background-light-blue p-3 balloon float-left position-relative w-100">{{template}}</div>
</div>


