import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[appCaseFormatter]',
    standalone: false
})
export class CaseFormatterDirective {
    @Input('appCaseFormatter') case = ''
  constructor(readonly el: ElementRef) { }

    @HostListener('input', ['$event']) onInput(event: Event){
        const input = this.el.nativeElement as HTMLInputElement;
        if(this.case.toLowerCase() == 'lower'){
            input.value = input.value.toLowerCase()
        }else if (this.case.toLowerCase() == 'upper'){
            input.value = input.value.toUpperCase()
        }
    }

}
