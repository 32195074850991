import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";

const apiUrl: string = environment.endpointAPI;

@Injectable({
    providedIn: 'root'
})
export class ActionProviderService {
    private endpointUrl: string = `${apiUrl}/v1/actionprovider`;

    constructor(private http: HttpClient) {
    }

    getAllActionProvider(): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}`);
    }
}
