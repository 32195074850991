import {IBaseModel, ILabelInterface} from "../../models/common.model";

export const monthsYear: IBaseModel[] = [
    {id: 1, name: 'labels.january'},
    {id: 2, name: 'labels.february'},
    {id: 3, name: 'labels.march'},
    {id: 4, name: 'labels.april'},
    {id: 5, name: 'labels.may'},
    {id: 6, name: 'labels.june'},
    {id: 7, name: 'labels.july'},
    {id: 8, name: 'labels.august'},
    {id: 9, name: 'labels.september'},
    {id: 10, name: 'labels.october'},
    {id: 11, name: 'labels.november'},
    {id: 12, name: 'labels.december'},
];


export const daysOfWeekConstants: IBaseModel[] = [
    {id: 1, name: 'labels.sunday'},
    {id: 2, name: 'labels.monday'},
    {id: 3, name: 'labels.tuesday'},
    {id: 4, name: 'labels.thursday'},
    {id: 5, name: 'labels.wednesday'},
    {id: 6, name: 'labels.friday'},
    {id: 7, name: 'labels.saturday'}
];

export const timeUnitsConstants: ILabelInterface[] = [
    {value: 5, label: 'labels.minutes'},
    {value: 6, label: 'labels.hours'},
    {value: 0, label: 'labels.days'},
];
