import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Component, Inject} from '@angular/core';
import {SharedManageService} from "../../shared.manage.service";

@Component({
    selector: 'app-view-template-dialog',
    templateUrl: './view-template-dialog.component.html',
    styleUrls: ['./view-template-dialog.component.scss'],
    standalone: false
})
export class ViewTemplateDialogComponent {
    template: string = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) protected data: string,
        private sharedManage: SharedManageService,
    ) {

        this.removeBraces(data)
    }

    public removeBraces(label: string): void {
        this.template = this.sharedManage.removeBraces(label ?? '');
    }
}
