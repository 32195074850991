<div class="m-4">
    <div class="col-12 d-flex justify-content-between">
        <h3 class="m-0">{{'labels.advancedFilter' | translate}}</h3>
        <mat-icon class="icon-actions minor-highlight" (click)="exit(false)">cancel</mat-icon>
    </div>
    <hr class="col my-3">

    <form [formGroup]="formFilter" class="row overflow">
        <div *ngIf="data.component === 'quiz-analyze'" class="col-12">
            <app-autocomplete (itemSelected)="formFilter.get('quizId')?.setValue($event?.id ?? '')"
                              [attribute]="'name'"
                              [returnAttribute]="'id'"
                              [label]="'labels.quiz'"
                              [list]="allQuiz"
                              [field]="formFilter.get('quizId')">
            </app-autocomplete>
        </div>

        <app-autocomplete class="col-md-6 col-12"
                          (itemSelected)="selectConversation($event ?? {})"
                          [attribute]="'name'"
                          [returnAttribute]="'id'"
                          [label]="'labels.conversation'"
                          [list]="allConversations"
                          [field]="formFilter.get('monesConversationId')">
        </app-autocomplete>
        <app-autocomplete class="col-md-6 col-12"
                          (itemSelected)="selectDialog($event)"
                          [attribute]="'name'"
                          [returnAttribute]="'id'"
                          [label]="'labels.dialogs'"
                          [list]="allDialogs"
                          [field]="formFilter.get('dialogId')">
        </app-autocomplete>

        <app-autocomplete class="col-md-6 col-12"
                          [attribute]="'name'"
                          [returnAttribute]="'id'"
                          [label]="'labels.actionProvider'"
                          [list]="allProvider"
                          [field]="formFilter.get('providerId')">
        </app-autocomplete>

        <ng-container *ngIf="data.component !== 'training-conversation' && data.component !== 'curation'">
            <mat-form-field class="input-sm col-md-6 col-12">
                <mat-label>{{'labels.type' | translate}}</mat-label>
                <mat-select formControlName="typeDialogActionId">
                    <mat-option>{{'labels.select' | translate}}</mat-option>
                    <mat-option *ngFor="let type of typeDialogActionId"
                                [value]="type.value">{{type.label! | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
            <app-autocomplete class="col-md-6 col-12"
                              [attribute]="'name'"
                              [returnAttribute]="'name'"
                              [label]="'labels.group'"
                              [list]="allGroups"
                              [field]="formFilter.get('groupingName')">
            </app-autocomplete>
            <app-autocomplete class="col-md-6 col-12"
                              [attribute]="'name'"
                              [returnAttribute]="'id'"
                              [label]="'labels.dialogActionName'"
                              [list]="allNomeActions"
                              [field]="formFilter.get('dialogActionId')">
            </app-autocomplete>
        </ng-container>

        <mat-form-field class="input-sm col-md-6 col-12" *ngIf="data.component === 'training-conversation' ||
        data.component === 'curation'">
            <mat-label>{{'labels.statusTrainning' | translate}}</mat-label>
            <mat-select formControlName="statusTrainning">
                <ng-container *ngFor="let status of listStatus">
                    <mat-option [value]="status.value">{{status.label! | translate}}</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="input-sm col-12">
            <mat-label>{{'labels.partDialog' | translate}}</mat-label>
            <input matInput formControlName="partDialog">
        </mat-form-field>

        <form [formGroup]="formFilterDate" class="row col-12 m-0 p-0">
            <app-date-picker class="col-lg-3 col-md-6 col-12"
                             [field]="formFilterDate.get('dateStartOnly')"
                             label="labels.dateStart"
                             (valueChanged)="formFilterDate.get('dateStartOnly')?.setValue($event);
                             handleDateChange('hoursStart', 'dateStartOnly')"
                             [max]="formFilterDate.get('dateEndOnly')?.value">
            </app-date-picker>
            <mat-form-field class="input-sm col-lg-3 col-md-6 col-12">
                <mat-label>{{'labels.hourStart' | translate}}</mat-label>
                <input formControlName="hoursStart" matInput type="text" appTimeFormatter
                       placeholder="{{'inputs.hourPickerFormat' | translate}}"
                       (keyup)="handleDateChange('hoursStart', 'dateStartOnly')">
            </mat-form-field>

            <app-date-picker class="col-lg-3 col-md-6 col-12"
                             [field]="formFilterDate.get('dateEndOnly')"
                             label="labels.dateEnd"
                             placeholder="labels.dateEnd"
                             (valueChanged)="formFilterDate.get('dateEndOnly')?.setValue($event);
                             handleDateChange('hoursEnd', 'dateEndOnly')"
                             [min]="formFilterDate.get('dateStartOnly')?.value">
            </app-date-picker>
            <mat-form-field class="input-sm col-lg-3 col-md-6 col-12">
                <mat-label>{{'labels.hourEnd' | translate}}</mat-label>
                <input formControlName="hoursEnd" matInput type="text" appTimeFormatter
                       placeholder="{{'inputs.hourPickerFormat' | translate}}"
                       (keyup)="handleDateChange('hoursEnd', 'dateEndOnly')">
            </mat-form-field>
        </form>

        <mat-form-field class="input-sm col-md-4 col-12">
            <mat-label>{{'labels.name' | translate}}</mat-label>
            <input matInput formControlName="name">
        </mat-form-field>
        <mat-form-field class="input-sm col-md-4 col-12">
            <mat-label>{{'labels.email' | translate}}</mat-label>
            <input matInput formControlName="email">
        </mat-form-field>
        <mat-form-field class="input-sm col-md-4 col-12">
            <mat-label>{{'labels.cellphone' | translate}}</mat-label>
            <input matInput formControlName="phone" numbersOnly>
        </mat-form-field>
    </form>

    <div class="row d-flex justify-content-end col mt-1">
        <div class="col-6 col-md-auto">
            <button mat-fab extended class="button-actions-secondary text w-100"
                    matTooltip="{{'buttons.clearFilters' | translate}}" (click)="clearFilter()">
                <mat-icon class="d-none d-sm-block">cancel</mat-icon>
                {{'buttons.clearFilters' | translate}}
            </button>
        </div>
        <div class="col-6 col-md-auto">
            <button mat-fab extended class="button-actions-primary text w-100" (click)="exit(true)"
                    matTooltip="{{'labels.applyFilter' | translate}}">{{'labels.applyFilter' | translate}}
                <mat-icon class="d-none d-sm-block">filter_list</mat-icon>
            </button>
        </div>
    </div>
</div>
