import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Component, Inject, OnInit} from '@angular/core';

@Component({
    selector: 'app-dialog-alert',
    templateUrl: './alert-dialog.component.html',
    styleUrls: ['./alert-dialog.component.scss'],
    standalone: false
})
export class AlertDialogComponent implements OnInit {
    confirmationOnly: boolean = false;
    alertMessage: string = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<AlertDialogComponent>,
    ) {
        this.alertMessage = data.message ?? data;
        this.confirmationOnly = data.confirmationOnly;
    }

    ngOnInit(): void {
    }

    exitOrConfirm(exitOrConfirm: boolean): void {
        this.dialogRef.close(exitOrConfirm);
    }
}
