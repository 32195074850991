import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {Injectable} from "@angular/core";
import {AlertDialogComponent} from "./component/dialog-alert/alert-dialog.component";
import {daysOfWeekConstants, monthsYear} from "./constants/units-of-time.constants";
import {MotorMessageService} from "../service/motor-message.service";
import {recurrenceSequence} from "./constants/sequence.constants";
import {FileService} from "../service/file.service";
import {ISchedule} from "../models/schedule.model";
import {DateUtils} from "./utils/date-utils";
import {Observable, take} from "rxjs";
@Injectable({
    providedIn: 'root'
})
export class SharedManageService {
    private text: string = '';

    constructor(
        private motorMessage: MotorMessageService,
        private translate: TranslateService,
        private fileService: FileService,
        private dateUtils: DateUtils,
        private dialog: MatDialog,
    ) {
    }

    conversationMia(): void {
        // TODO: the logic for Mia's chat will be developed here
        this.motorMessage.openSnackBar('Em Breve...')
    }

    public confirmAction(message: string, confirmationOnly?: boolean): Observable<boolean> {
        const dialogRef = this.dialog.open(AlertDialogComponent, {
            width: window.innerWidth > 850 ? '40%' : '100%',
            data: {message, confirmationOnly},
        });
        return dialogRef.afterClosed();
    }

    public displayFile(hasBucket: boolean, path: string): void {
        const fileRequest = {hasBucket: hasBucket, path: path}
        this.fileService.exportFile(fileRequest).pipe(take(1))
            .subscribe(value => window.open(value.data.result, '_blank'));
    }

    public importResponse(fileEvent: any, item: any): void {
        const file = fileEvent.target.files[0];
        const formData = new FormData();
        formData.append('file', file, file.name);
        this.fileService.uploadMultiPartFile(formData).subscribe({
            next: value => {
                item.fileName = file.name;
                item.fileSize = file.size;
                item.fileType = value.data.result.path.split('.').pop();
                item.path = value.data.result.path;
            },
        });
    }

    public scheduleToString(schedule: ISchedule): string {
        const {dateWithFormat, dateTime} = this.dateUtils.formatDateAndTimeToSpecificFormat(schedule.dateExecution,
            schedule.dateExecutionTimeZone, 'inputs.datePickerFormat', 'labels.hourMinuteFormatOutPut');

        const translateLabel = (label: string) => {
            return this.translate.instant(label);
        };

        if (schedule.campaing){
            this.text = dateWithFormat + ' ' + translateLabel('labels.at') + ' ' + dateTime + 'h '
                + translateLabel('labels.sendEndsAfterOneOccurrence');
            return this.text
        }

        this.text = dateWithFormat + ' ' + translateLabel('labels.at') + ' ' + dateTime + 'h '
            + translateLabel('labels.theFemale') + ' ' + translateLabel('labels.action') + ' ' +
            translateLabel(schedule.dialogAction?.name!) + ' ';

        const translateDayOfWeek = (weekday: number) => {
            return this.translate.instant(daysOfWeekConstants[weekday].name!);
        };

        const translateMonth = (month: number) => {
            return this.translate.instant(monthsYear[month - 1].name!);
        };

        const translateSequence = (sequence: number) => {
            return this.translate.instant(recurrenceSequence[sequence - 1].name!);
        };

        const translateRecurrenceType = (type: number): void => {
            switch (type) {
                case 0:
                    this.text += translateLabel('labels.daily') + ' ';
                    translateRecurrenceControl(schedule.recurrenceControl!);
                    break;
                case 1:
                    this.text += translateLabel('labels.weekly') + ' ' +
                        translateLabel('labels.repeatEvery') + ' ' + schedule.recurrenceTimes + ' ' +
                        translateLabel('labels.week') + '(s) ' + translateLabel('labels.inDaysOfWeek') + ': ';
                    const newWeeks: any = [];
                    schedule.weekdays!.forEach((weekday: any) => {
                        newWeeks.push(translateDayOfWeek(weekday));
                    });
                    this.text += newWeeks.join(', ');
                    break;
                case 2:
                    this.text += translateLabel('labels.monthly') + ' ';
                    translateRecurrenceControl(schedule.recurrenceControl!);
                    break;
                case 3:
                    this.text += translateLabel('labels.annually') + ' ' +
                        translateLabel('labels.repeatEvery') + ' ' + schedule.recurrenceTimes + ' ' +
                        translateLabel('labels.year') + '(s) ';
                    translateRecurrenceControl(schedule.recurrenceControl!);
                    break;
                default:
                    break;
            }
        };

        const translateRecurrenceControl = (control: number): void => {
            switch (control) {
                case 0:
                    this.text += translateLabel('labels.every') + ' ' + schedule.recurrenceTimes + ' ' +
                        translateLabel('labels.day') + '(s) ';
                    break;
                case 1:
                    this.text += translateLabel('labels.everyWeekday') + ' ';
                    return;
                case 2:
                    this.text += translateLabel('labels.day') + ' ' + schedule.recurrenceTimes + ' ' +
                        translateLabel('labels.ofEvery') + ' ' + schedule.recurrenceMonthValue + ' ' +
                        translateLabel('labels.month') + translateLabel('labels.pluralS');
                    return;
                case 3:
                    this.text += translateLabel('labels.theFemale') + ' ' +
                        translateSequence(schedule.recurrenceSequence! - 1) + ' ' +
                        translateDayOfWeek(schedule.recurrenceWeek! - 1) + ' ' +
                        translateLabel('labels.ofEvery') + ' ' + schedule.recurrenceMonthValue + ' ' +
                        translateLabel('labels.month') + translateLabel('labels.pluralS');
                    return;
                case 4:
                    this.text += translateLabel('labels.on') + ' ' +
                        translateMonth(schedule.recurrenceMonth! - 1) + ' ' + schedule.recurrenceMonthValue;
                    return;
                case 5:
                    this.text += translateLabel('labels.onTheFemale') + ' ' +
                        translateSequence(schedule.recurrenceSequence! - 1) + ' ' +
                        translateDayOfWeek(schedule.recurrenceWeek! - 1) + ' ' +
                        translateLabel('labels.of') + ' ' +
                        translateMonth(schedule.recurrenceMonth! - 1) + ' ';
                    return;
                default:
                    break;
            }
        };
        translateRecurrenceType(schedule.recurrenceType!);

        if (schedule.period === 1) {
            this.text += translateLabel('labels.terminateAfter') + ' ' + schedule.periodOccurrence + ' ' +
                translateLabel('labels.ocurrences');
        } else if (schedule.period === 2) {
            this.text += ', ' + translateLabel('labels.terminateAt') + ' ' + this.dateUtils
                .formatDateAndTimeToSpecificFormat(schedule.periodDateEnd, '', 'inputs.datePickerFormat');
        }
        return this.text;
    }

    public getTypeSchedule(schedule: ISchedule): string {
        if (schedule.blockDialogAction) {
            return 'labels.blocking';
        } else if (schedule.campaing) {
            return 'labels.campaign';
        } else if (schedule.dialogAction!.dialogActionCondition == 2) {
            return 'labels.activation';
        }
        return 'labels.message';
    };

    public getIconStatus(schedule: ISchedule): string {
        if (schedule.monesConversationStatus == 0) {
            return "text-warning fa-solid fa-circle-exclamation";
        } else if (schedule.monesConversationStatus == 1) {
            return "text-danger fa-times-circle";
        } else if (schedule.monesConversationStatus == 2 || schedule.monesConversationStatus == 3) {
            return "text-success fa-check-circle";
        }
        return '';
    }

    public removeBraces(label: string): string {
        if (!label) return 'labels.emptyTemplate';
        return label.replace(/{{(\d+)}}/g, '$1');
    }

    public export(service: any, endpoint: string, request: any, elementName: string): void {
        service[endpoint](request).pipe(take(1)).subscribe((blob: Blob) => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${elementName}.csv`;
            link.click();
            window.URL.revokeObjectURL(link.href);
        });
    }

    public prepareFileForUpload(fileEvent: any, fileId: number): Promise<any> {
        return new Promise((resolve) => {
            const file = fileEvent.target.files[0];
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = (element): any => {
                if (!element.target) return;
                const base64Data: string = (element.target.result as string).split('base64,')[1];
                const fileType: string = (file.name.split(".").pop() ?? '').toLowerCase();
                resolve({
                    fileId: fileId,
                    file: base64Data,
                    extension: fileType,
                    provider: 's3',
                    maxSize: '5',
                    folder: 'project'
                });
            };
        });
    }

    public hourRequiredIfDateFilled(dateControlName: string, hourControlName: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const dateControl = (control as FormGroup).controls[dateControlName];
            const hourControl = (control as FormGroup).controls[hourControlName];

            if (dateControl && hourControl && dateControl.value && !hourControl.value) {
                hourControl.setErrors({'required': true});
                return {'hourRequired': true};
            } else if (hourControl) {
                hourControl.setErrors(null);
            }

            return null;
        };
    }
}
