export const environment = {
    endpointAPI: 'https://api-dev.omotor.com.br',
    endpointHelpDesk: 'https://api-helpdesk-dev.omotor.com.br',
    blackboxApi: 'https://chatgpt-dev-blackbox.omotor.com.br',
    facebookClientId: '127979581281039',
    tokenWebChat: 'OrVPcMk0f2M.MYD3Utz2XnYNu1tHmUt9uxZZLEtQEdYsScr5MA9eDZ8',
    excludedLoaderEndpoints: [
        'api.openai.com',
    ],
    excludedTokenEndpoints: [
        'api.openai.com',
        'chatgpt-dev-blackbox.omotor.com.br',
        'gptbb-dev.s3.us-west-2.amazonaws.com',
    ],
}
