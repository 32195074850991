import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class MotorMessageService {

    constructor(
        private _snackBar: MatSnackBar,
        private translate: TranslateService,
    ) {
    }

    public openSnackBar(messageKey: string, additionalInformation?: string, action?: string) {
        this.translate.get(messageKey).subscribe((label: string) => {
            this._snackBar.open(label + (additionalInformation ?? ''), action, {
                duration: 5000,
                verticalPosition: 'top',
                horizontalPosition: 'right',
            });
        });
    }
}
