<div class="m-4">
    <div class="d-flex justify-content-center my-3 teste">
        <mat-icon class="icon-actions delete mx-3 icon-info">info</mat-icon>
    </div>
    <h2 class="text-center text-break">{{alertMessage | translate}}</h2>

    <div class="d-flex justify-content-end mt-3" *ngIf="!confirmationOnly">
        <div class="row col-md-auto col-12 d-flex">
            <div class="col-6 col-md-auto">
                <button mat-button class="button-actions-minor-highlight text w-100"
                        (click)="exitOrConfirm(false)">
                    {{'buttons.back' | translate}}
                </button>
            </div>
            <div class="col-6 col-md-auto">
                <button mat-button class="button-actions-primary text w-100"
                        (click)="exitOrConfirm(true)">
                    {{'buttons.confirm' | translate}}
                </button>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center my-3" *ngIf="confirmationOnly">
        <div class="col-12 col-md-auto pe-1">
            <button mat-button class="button-actions-primary text w-100 me-md-2"
                    (click)="exitOrConfirm(false)">
                {{'buttons.ok' | translate}}
            </button>
        </div>
    </div>
</div>
