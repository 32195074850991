import {Component, Input, OnChanges, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AuthenticationService} from "../../../pages/authentication/authentication.service";
import * as BotChat from './js/botchat.js';
import {Subscription, take} from "rxjs";
import * as $ from 'jquery';

@Component({
    selector: 'app-web-chat',
    templateUrl: './web-chat.component.html',
    styleUrls: ['./web-chat.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class WebChatComponent implements OnInit, OnDestroy, OnChanges {
    @Input() environmentType: string = '';
    @Input() slugBot: string = '';
    @Input() token: string = '';
    private currentUserSub: Subscription = new Subscription();
    private currentUserToken: string = '';
    private currentEmail: string = '';
    private dl: BotChat;

    constructor(private authService: AuthenticationService) {
    }

    ngOnInit(): void {
        this.currentUserSub = this.authService.getCurrentUserObservable().pipe(take(1)).subscribe(user => {
            this.currentEmail = user.email!;
            this.currentUserToken = user.token!;
            if (!localStorage.getItem('userEmailMones')) {
                localStorage.setItem('userEmailMones', this.currentEmail);
            }
            this.ngOnChanges();
        });
    }

    ngOnChanges(): void {
        if (localStorage.getItem('userEmailMones') && this.slugBot) {
            this.createChat(
                this.token,
                localStorage.getItem('userEmailMones'),
                '',
                this.environmentType,
                this.slugBot,
                this.currentUserToken
            );
        }
    }

    ngOnDestroy(): void {
        if (this.currentUserSub) {
            this.currentUserSub.unsubscribe();
        }
        if (this.dl) {
            this.dl.end();
        }
    }

    createChat(t: any, u: any, s: any, a: any, slugBot: any, tokenMones: any): void {
        const conversationId = localStorage.getItem('mones-' + u + a + slugBot);
        if (!conversationId) {
            this.createConversationId(t, u, s, a, slugBot, tokenMones);
        } else {
            this.validConversationId(conversationId, t, u, s, a, slugBot, tokenMones);
        }
    }

    createConversationId(t: any, u: any, s: any, a: any, slugBot: any, tokenMones: any): void {
        const self = this;
        const botConnection = new BotChat.DirectLine({secret: t});

        $.ajax({
            type: "POST",
            url: botConnection.domain + "/conversations?watermark=" + 'mones-' + u + a,
            dataType: 'json',
            success: function (data, status) {
                localStorage.setItem('mones-' + u + a + slugBot, data.conversationId);
                self.createBotChat(data.conversationId, t, u, s, a, slugBot, tokenMones);
            },
            beforeSend: function (xhr, settings) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + t);
            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {
                alert("Some error" + errorThrown);
            }
        });
    }

    validConversationId(conversationId: any, t: any, u: any, s: any, a: any, slugBot: any, tokenMones: any): void {
        const self = this;
        const botConnection = new BotChat.DirectLine({secret: t});

        $.ajax({
            type: "GET",
            url: botConnection.domain + '/conversations/' + conversationId + '/activities?watermark=6',
            dataType: 'json',
            success: function () {
                self.createBotChat(conversationId, t, u, s, a, slugBot, tokenMones);
            },
            error: function (XMLHttpRequest, textStatus, errorThrown) {
                console.error("Error validating conversation:", textStatus, errorThrown);
                localStorage.setItem('mones-' + u + a + slugBot, '');
                self.createConversationId(t, u, s, a, slugBot, tokenMones);
            },
            beforeSend: function (xhr, settings) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + t);
            }
        });
    }

    createBotChat(conversationId: any, t: any, u: any, s: any, a: any, slugBot: any, tokenMones: any): any {
        if (this.dl) {
            this.dl.end();
            const botElement = document.getElementById("bot");
            botElement!.innerHTML = "";
        }

        this.dl = new BotChat.DirectLine({
            secret: t,
            webSocket: false,
            conversationId: conversationId
        });

        BotChat.App({
            botConnection: this.dl,
            user: {
                id: 'mones-' + u + a + slugBot,
                name: null,
                token: s,
                tokenmones: tokenMones,
                slugBotGptConsole: slugBot
            },
            bot: {id: 'MonesWebchat'},
            resize: 'detect'
        }, document.getElementById("bot"));
    }
}
