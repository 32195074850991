import {
    ChangeDetectorRef,
    Component,
    DoCheck,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {FormControl} from "@angular/forms";
import {MotorMessageService} from "../../../service/motor-message.service";
import {filter, fromEvent, map, Observable, startWith, take} from "rxjs";

@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
    standalone: false
})
export class AutocompleteComponent implements OnInit, DoCheck, OnChanges {
    @ViewChild('autocompleteElement') autocompleteElement!: ElementRef;

    @Input() set list(list: any[]) {
        this._list = list.sort((a, b) => a[this.attribute].localeCompare(b[this.attribute]));
    };

    get list(): any[] {
        return this._list;
    }

    @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();
    @Input() returnAttribute: string = '';
    @Input() attribute: string = '';
    @Input() label: string = '';
    @Input() field: any;
    @Input() value: any;
    filteredItems: Observable<any[]> = new Observable<[]>;
    showField: FormControl = new FormControl;
    private _list: any[] = [];

    constructor(
        private cdRef: ChangeDetectorRef,
        private motorMessage: MotorMessageService,
        private translate: TranslateService,
    ) {
    }

    ngOnInit(): void {
        this.filteredItems = this.showField.valueChanges
            .pipe(startWith(''), map(value => this.filter(value || '')));
        this.field.valueChanges.subscribe((value: any) => {
            const fieldValue = (this.returnAttribute ? this.list
                    ?.find(x => x?.[this.returnAttribute] == value)?.[this.attribute] :
                value?.[this.attribute])
            if (fieldValue !== this.showField.value) this.showField.setValue(fieldValue ?? '');
        });

        this.filteredItems = this.showField.valueChanges
            .pipe(startWith(''), map(value => this.filter(value || '')));
        if (this.field.value !== this.showField.value) {
            const fieldValue = this.returnAttribute ? this.list
                ?.find(x => x?.[this.returnAttribute] == this.field.value)?.[this.attribute] : this.field.value?.[this.attribute];
            if (fieldValue) {
                this.showField.setValue(this.translate.instant(fieldValue) ?? '');
            }
        }
    }

    ngOnChanges(): void {
        if (this.field.value !== this.showField.value) {
            const fieldValue = this.returnAttribute ? this.list
                ?.find(x => x?.[this.returnAttribute] == this.field.value)?.[this.attribute] : this.field.value?.[this.attribute];
            if (fieldValue) {
                this.showField.setValue(this.translate.instant(fieldValue) ?? '');
            }
        }

        this.field.valueChanges.subscribe((value: any) => {
            const fieldValue = (this.returnAttribute ? this.list
                    ?.find(x => x?.[this.returnAttribute] == value)?.[this.attribute] :
                value?.[this.attribute]);
            if (fieldValue !== this.showField.value) {
                if (fieldValue) {
                    this.translate.get(fieldValue ?? '').subscribe(translation => {
                        this.showField.setValue(translation ?? '');
                    });
                } else {
                    this.showField.reset()
                }
            }
        });
    }

    ngDoCheck(): void {
        this.field.disabled ? this.showField.disable() : this.showField.enable();
        this.showField.clearValidators();
        this.showField.setValidators(this.field.validator);
        this.showField.updateValueAndValidity();

        if (this.field.touched !== this.showField.touched) {
            this.showField.markAsTouched();
            this.cdRef.detectChanges();
        }
    }

    filter(value: any): any {
        if (typeof value === 'object') return this.list;
        return this.list?.filter(option => option?.[this.attribute].toString().toLowerCase().includes(value.toString().toLowerCase()));
    }

    selectItem(value: any): void {
        this.showField.setValue(value?.[this.attribute]);
        this.field.setValue(this.returnAttribute ? value?.[this.returnAttribute] : value);
        this.itemSelected.emit(value);
    }

    resetField(): void {
        this.field.reset();
        this.showField.reset();
        this.itemSelected.emit();
    }

    focusout(): void {
        fromEvent(document, 'click').pipe(
            filter((event: Event) => !this.autocompleteElement?.nativeElement?.contains(event)),
            take(1)
        ).subscribe(() => {
            if (!this.showField.value) return;
            const field = this.field.value;
            const fieldSelected = this.returnAttribute ? this.list
                .find(x => x?.[this.returnAttribute] == field)?.[this.attribute] : field?.[this.attribute];
            if (fieldSelected === this.showField.value) return;
            this.motorMessage.openSnackBar('labels.optionNotSelected');
            this.resetField();
        });
    }
}
