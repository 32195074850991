import {HttpClient} from "@angular/common/http";
import {Injectable, OnDestroy} from "@angular/core";
import {Observable, Subscription} from "rxjs";
import {AuthenticationService} from "../pages/authentication/authentication.service";
import {environment} from "../../environments/environment";

const apiUrl: string = environment.endpointAPI;

@Injectable({
    providedIn: 'root'
})
export class DialogService implements OnDestroy {
    private endpointUrl: string = `${apiUrl}/v1/dialog`;
    public workspaceId: number = 0;
    workspaceSub: Subscription;

    constructor(
        private authService: AuthenticationService,
        private http: HttpClient
    ) {
        this.workspaceSub = this.authService.getCurrentUserObservable()
            .subscribe(user => this.workspaceId = user.workspaceId!);
    }

    ngOnDestroy(): void {
        if (this.workspaceSub) {
            this.workspaceSub.unsubscribe();
        }
    }

    getAllDialog(): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/${this.workspaceId}`);
    }

    getCognition(dialogId: number, message: string): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/cognition/${dialogId}/${message}`);
    }

    getDialogActions(dialogId: number): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/action/mones/dialog/${dialogId}`);
    }

    findDialogByWorkspaceId(id: number, name?: string): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/${id}?name=${name ?? ''}`);
    }

    findByDialogId(dialogId: number): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/${dialogId}/${this.workspaceId}`)
    }

    saveDialog(data: any): Observable<any> {
        return this.http.post<any>(`${this.endpointUrl}`, {...data, workspace: {id: this.workspaceId}});
    }

    updateDialog(data: any): Observable<any> {
        data.workspace = {
            id: this.workspaceId
        };
        return this.http.put<any>(`${this.endpointUrl}`, data);
    }

    deleteDialog(id: number): Observable<any> {
        return this.http.delete<any>(`${this.endpointUrl}/${id}`);
    }

    exportDialogAsync(dialogId: number, emails: string): Observable<string> {
        let mails = encodeURIComponent(emails);
        return this.http.get(`${this.endpointUrl}/report/async/${dialogId}/${mails}/`, {responseType: 'text'});
    }

    exportDialog(dialogId: number): Observable<string> {
        return this.http.get(`${this.endpointUrl}/report/${dialogId}`, {responseType: 'text'});
    }

    publishDialog(dialogId: number): Observable<any> {
        return this.http.post<any>(`${this.endpointUrl}/publish/${dialogId}`, {});
    }

    findDialogActions(dialogId: number, filter: any): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/action/${dialogId}/${this.workspaceId}`, {params: filter});
    }

    findDialogActionsGrouping(dialogId: number, queryParams: any): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/action/${dialogId}/${this.workspaceId}`, {params: queryParams});
    }

    findDialogEnthusiasmByDialog(dialogId: number): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/enthusiasm/${dialogId}`);
    }

    getDialogActionsWithFilter(dialogId: number, filter: any): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/action/${dialogId}/${this.workspaceId}`, {params: filter});
    }

    findBySlug(slug: string): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/slug/${slug}`);
    }

    findDialogActionsNotDialogActionId(dialogId: number, dialogActionId: number): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/action/combo/${dialogId}/${this.workspaceId}/${dialogActionId}`);
    }

    findActionById(dialogId: number, dialogActionId: number): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/action/${this.workspaceId}/${dialogId}/${dialogActionId}`);
    }

    updateDialogAction(request: any) {
        request.dialog.workspace = {
            id: this.workspaceId
        }
        return this.http.put<any>(`${this.endpointUrl}/action`, request);
    }

    updateSystemDialogAction(request: any) {
        request.dialog.workspace = {
            id: this.workspaceId
        }
        return this.http.put<any>(`${this.endpointUrl}/action/system`, request);
    }

    findIfDialogActionIsValid(dialogId: number, dialogActionId: number): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/action/${this.workspaceId}/${dialogId}/${dialogActionId}/valid/delete`);
    }

    deleteDialogAction(dialogId: number, dialogActionId: number): Observable<any> {
        return this.http.delete<any>(`${this.endpointUrl}/action/${this.workspaceId}/${dialogId}/${dialogActionId}`);
    }

    saveActionSystem(actionId: number | string): Observable<any> {
        return this.http.post<any>(`${this.endpointUrl}/action/system/${actionId}`, {});
    }

    saveDialogAction(data: any): Observable<any> {
        data.dialog.workspace = {
            id: this.workspaceId
        }
        return this.http.post<any>(`${this.endpointUrl}/action/`, data);
    }
}
