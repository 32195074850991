import {OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {AuthenticationService} from "../../pages/authentication/authentication.service";
import {Subscription} from "rxjs";
import moment from "moment";
import 'moment-timezone';

@Pipe({
    name: 'userTimezoneDate',
    standalone: false
})
export class UserTimezoneDatePipe implements PipeTransform, OnDestroy {
    private userTimezone: string = '';
    private timezoneSub: Subscription;

    constructor(
        private authService: AuthenticationService,
        private translate: TranslateService,
    ) {
        this.timezoneSub = this.authService.getCurrentUserObservable()
            .subscribe(user => this.userTimezone = user.timezone!.timezone!);
    }

    ngOnDestroy(): void {
        if (this.timezoneSub) {
            this.timezoneSub.unsubscribe();
        }
    }

    transform(value: any, format: string): any {
        if (!value) return value;
        return moment(value).tz(this.userTimezone).format(this.translate.instant(format));
    }
}
