import {MatPaginatorIntl} from '@angular/material/paginator';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, take} from "rxjs";
import {DecimalPipe} from "@angular/common";

export class MatPaginatorIntlTranslate extends MatPaginatorIntl {

    constructor(private translate: TranslateService, private decimalPipe: DecimalPipe) {
        super();
        const labels = ['paginator.itemsPerPage', 'paginator.nextPage', 'paginator.previousPage', 'paginator.firstPage', 'paginator.lastPage'];
        const requests = labels.map(label => this.translate.get(label).pipe(take(1)));

        combineLatest(requests).subscribe(([itemsPerPageLabel, nextPageLabel, previousPageLabel, firstPageLabel, lastPageLabel]) => {
            this.itemsPerPageLabel = itemsPerPageLabel;
            this.nextPageLabel = nextPageLabel;
            this.previousPageLabel = previousPageLabel;
            this.firstPageLabel = firstPageLabel;
            this.lastPageLabel = lastPageLabel;
        });
    }

    override getRangeLabel = (page: number, pageSize: number, length: number): string => {
        const ofTranslation = this.translate.instant('paginator.of');

        if (length === 0 || pageSize === 0) {
            return `0 ${ofTranslation} ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${ofTranslation} ${this.decimalPipe.transform(length)}`;
    }
}
