import {Injectable, signal} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, ReplaySubject} from 'rxjs';
import {LocalstorageService} from 'src/app/shared/services/localstorage.service';
import {environment} from 'src/environments/environment';
import {IUser} from 'src/app/models/user.model';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    currentUserSubject = new ReplaySubject<IUser>(1);
    currentUser = signal<IUser>({} as IUser);
    private readonly apiURL: string = environment.endpointAPI;

    constructor(
        private http: HttpClient,
        private router: Router,
        private lsService: LocalstorageService
    ) {
        this.currentUser.set(JSON.parse(this.lsService.getItem('user') || '{}'));
        this.setCurrentUser();
    }

    getCurrentUserObservable(): Observable<IUser> {
        return this.currentUserSubject.asObservable();
    }

    setCurrentUser(): void {
        this.currentUserSubject.next(this.currentUser());
    }

    authenticate(email: string, password: string): Observable<IUser> {
        return this.http.post<IUser>(`${this.apiURL}/auth/signin`, {email, password});
    }

    authenticateFederation(token: string): Observable<IUser> {
        return this.http.get<IUser>(`${this.apiURL}/auth/signin/federation/user`, {params: {"token":token}});
    }

    sendBeginRecoveryPassword(email: string) {
        return this.http.post(`${this.apiURL}/auth/password/beginReset`, {email});
    }

    checkRecoveryPassword(hash: string) {
        return this.http.post(`${this.apiURL}/auth/password/checkReset`, {hash});
    }

    sendEndRecoveryPassword(password: string, hash: string) {
        return this.http.post(`${this.apiURL}/auth/password/endReset`, {hash, password});
    }

    logout(): void {
        this.lsService.removeItem('user');
        this.router.navigateByUrl('/');
    }
}
