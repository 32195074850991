import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {Component, Inject} from '@angular/core';
import {CurationStatusLabels, TrainingStatusLabels} from "../../constants/status.constants";
import {MonesConversationService} from "../../../service/mones-conversation.service";
import {typeDialogActionId} from "../../constants/type-dialog-actionId.constant";
import {ActionProviderService} from "../../../service/action-provider.service";
import {AdvancedFilterManageService} from "./advanced-filter-manage.service";
import {MotorMessageService} from "../../../service/motor-message.service";
import {IBaseModel, ILabelInterface} from "../../../models/common.model";
import {GroupingService} from "../../../service/grouping.service";
import {IConversation} from "../../../models/conversation.model";
import {DialogService} from "../../../service/dialog.service";
import {QuizService} from "../../../service/quiz.service";
import {IProvider} from "../../../models/provider.model";
import {DateUtils} from "../../utils/date-utils";
import {take} from "rxjs";

@Component({
    selector: 'app-advanced-filter',
    templateUrl: './dialog-advanced-filter.component.html',
    styleUrls: ['./dialog-advanced-filter.component.scss'],
    standalone: false
})
export class DialogAdvancedFilterComponent {
    protected readonly typeDialogActionId = typeDialogActionId;
    allConversations: IConversation[] = [];
    listStatus: ILabelInterface[] = [];
    allNomeActions: IBaseModel[] = [];
    allDialogs: IBaseModel[] = [];
    allProvider: IProvider[] = [];
    allGroups: IBaseModel[] = [];
    allQuiz: IBaseModel[] = [];
    formFilterDate: FormGroup;
    formFilter: FormGroup;

    [key: string]: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<DialogAdvancedFilterComponent>,
        private advancedFilterManage: AdvancedFilterManageService,
        private conversationService: MonesConversationService,
        private actionProviderService: ActionProviderService,
        private motorMessage: MotorMessageService,
        private groupingService: GroupingService,
        private dialogService: DialogService,
        private translate: TranslateService,
        private quizService: QuizService,
        private dateUtils: DateUtils,
        private fb: FormBuilder,
    ) {
        this.formFilter = this.fb.group({
            quizId: new FormControl(''),
            monesConversationId: new FormControl(''),
            dialogId: new FormControl(''),
            providerId: new FormControl(''),
            typeDialogActionId: new FormControl(''),
            groupingName: new FormControl(''),
            dialogActionId: new FormControl(''),
            statusTrainning: new FormControl(),
            partDialog: new FormControl(''),
            dateStart: new FormControl(''),
            dateEnd: new FormControl(''),
            name: new FormControl(''),
            email: new FormControl('', Validators.email),
            phone: new FormControl(''),
        });
        this.formFilterDate = this.fb.group({
            dateStartOnly: new FormControl(''),
            hoursStart: new FormControl(),
            dateEndOnly: new FormControl(''),
            hoursEnd: new FormControl(''),
        });
        this.formFilter.patchValue(this.data.filter);
        this.startingFields();

        let statusList = this.data.component === 'curation' ? CurationStatusLabels : TrainingStatusLabels;
        statusList.forEach(status => {
            this.translate.get(status.label!).subscribe(translation => {
                status.label = translation;
                this.listStatus.push(status);
            });
        });
    }

    protected startingFields(): void {
        const {dialogId, monesConversationId, dateStart, dateEnd} = this.formFilter?.value;
        if (this.data.component === 'quiz-analyze' || this.data.component === 'curation') {
            this.validateDateMandatory();
        }
        this.formFilter.get('dialogActionId')?.disable();
        if (dialogId) {
            this.findDialogActions(dialogId);
        }
        if (this.formFilter.get('monesConversationId')?.value) {
            this.formFilter.get('dialogId')?.disable();
            this.formFilter.get('providerId')?.disable();
        }
        if (!monesConversationId && dialogId) {
            this.formFilter.get('monesConversationId')?.disable();
        }
        const {dateStartOnly, hoursStart, dateEndOnly, hoursEnd} = this.formFilterDate.controls;

        if (dateStart) {
            const {date, hours} = this.dateUtils.unformatDateAndTime(dateStart);
            this.formFilterDate.get('dateStartOnly')?.setValue(date);
            this.formFilterDate.get('hoursStart')?.setValue(hours);
            this.handleDateChange('hoursStart', 'dateStartOnly');
        } else if (this.data.component !== 'quiz-analyze' && this.data.component !== 'curation') {
            this.advancedFilterManage.clearValidators(dateStartOnly);
            this.advancedFilterManage.clearValidators(hoursStart);
        }
        if (dateEnd) {
            const {date, hours} = this.dateUtils.unformatDateAndTime(dateEnd);
            this.formFilterDate.get('dateEndOnly')?.setValue(date);
            this.formFilterDate.get('hoursEnd')?.setValue(hours);
            this.handleDateChange('hoursEnd', 'dateEndOnly');
        } else if (this.data.component !== 'quiz-analyze' && this.data.component !== 'curation') {
            this.advancedFilterManage.clearValidators(dateEndOnly);
            this.advancedFilterManage.clearValidators(hoursEnd);
        }
        this.populatingFilter();
    }

    private validateDateMandatory(): void {
        Object.keys(this.formFilterDate.controls).forEach(key => {
            this.formFilterDate.get(key)!.setValidators([Validators.required]);
            this.formFilterDate.get(key)!.updateValueAndValidity();
        });
    }

    private clearValidateDateMandatory(): void {
        const {dateStartOnly, hoursStart, dateEndOnly, hoursEnd} = this.formFilterDate.controls
        this.advancedFilterManage.clearValidators(dateStartOnly);
        this.advancedFilterManage.clearValidators(hoursStart);
        this.advancedFilterManage.clearValidators(dateEndOnly);
        this.advancedFilterManage.clearValidators(hoursEnd);
    }

    private populatingFilter(): void {
        if (this.data.component === 'quiz-analyze') {
            this.quizService.findActives().pipe(take(1)).subscribe({
                next: (quizzes) => this.allQuiz = quizzes.data.quizList
            });
        }
        if (this.data.component !== 'curation') {
            this.conversationService.getAllConversation().pipe(take(1)).subscribe({
                next: (conversations) => this.allConversations = conversations.data.conversations
            });
        } else {
            this.allConversations = this.data.conversations;
            this.formFilter.get('dialogId')?.disable();
            this.formFilter.get('providerId')?.disable();
        }

        this.dialogService.getAllDialog().pipe(take(1)).subscribe({
            next: (dialogs) => this.allDialogs = dialogs.data.dialogs
        });
        this.actionProviderService.getAllActionProvider().pipe(take(1)).subscribe({
            next: (providers) => this.allProvider = providers.data.actionProviders
        });
        if (this.data.component !== 'training-conversation' && this.data.component !== 'curation') {
            this.groupingService.getAllGrouping().pipe(take(1)).subscribe({
                next: (groupings) => this.allGroups = groupings.data.groupings
            });
        }
    }

    protected selectConversation(conversation: IConversation): void {
        const filterFields = ['monesConversationId', 'dialogActionId', 'dialogId', 'providerId'];
        if (!conversation || !(conversation.dialog?.id && conversation.id)) {
            filterFields.forEach(field => this.formFilter.get(field)?.reset());
            this.formFilter.get('dialogActionId')?.disable();
            if (this.data.component !== 'curation') {
                this.formFilter.get('dialogId')?.enable();
                this.formFilter.get('providerId')?.enable();
            }
        } else {
            const dialog = this.allDialogs.find(dialogs => dialogs.id === conversation.dialog?.id);
            const provider = this.allProvider.find(provider => provider.id === conversation.bot!.actionProvider!.id);
            this.formFilter.get('dialogId')?.setValue(dialog?.id ?? '');
            this.formFilter.get('providerId')?.setValue(provider?.id ?? '');
            this.formFilter.get('dialogId')?.disable();
            this.formFilter.get('providerId')?.disable();
            if (this.data.component !== 'training-conversation' && this.data.component !== 'curation') {
                this.findDialogActions(conversation.dialog.id);
            }
        }
    }

    private findDialogActions(dialogId: number): void {
        this.dialogService.getDialogActions(dialogId).pipe(take(1)).subscribe({
            next: value => {
                this.allNomeActions = value.data.dialogActions.map((action: IBaseModel) => {
                    return {...action, name: this.translate.instant(action.name!)};
                });
                if (!this.allNomeActions.length) return;
                this.formFilter.get('dialogActionId')?.enable();
            }
        });
    }

    protected selectDialog(dialog: IBaseModel): void {
        const dialogId = dialog?.id;
        if (dialogId) {
            this.findDialogActions(dialogId);
            this.formFilter.get('monesConversationId')?.disable();
            this.formFilter.get('monesConversationId')?.reset();
        } else {
            this.formFilter.get('monesConversationId')?.enable();
            this.formFilter.get('dialogId')?.reset();
            this.formFilter.get('dialogActionId')?.reset();
            this.formFilter.get('monesConversationId')?.enable();
            this.formFilter.get('dialogActionId')?.disable();
        }
    }

    protected clearFilter(): void {
        const component = this.data.component;
        if (component !== 'quiz-analyze' && component !== 'curation') {
            this.clearValidateDateMandatory();
        }
        this.formFilter.reset();
        this.formFilterDate.reset();
        if (component !== 'curation') {
            this.formFilter.enable();
        } else {
            this.formFilter.get('statusTrainning')?.setValue('PENDING');
        }

        this.formFilter.get('dialogActionId')?.disable();
    }

    protected async exit(applyFilter: boolean): Promise<void> {
        if (!this.formFilter.valid && applyFilter) {
            this.motorMessage.openSnackBar('errors.invalid.email');
            return;
        }
        if (applyFilter) {
            if (!this.advancedFilterManage.filterValid(this.formFilterDate)) return;
            await this.advancedFilterManage.adjustingDate(this.formFilterDate, this.formFilter);
        }
        Object.keys(this.formFilter.controls).forEach(key => {
            if (this.formFilter.get(key)?.value === null || this.formFilter.get(key)?.value === undefined) {
                this.formFilter.get(key)?.setValue('');
            }
        });
        this.dialogRef.close({applyFilter: applyFilter, filter: {...this.formFilter.getRawValue()}});
    }

    protected handleDateChange(hoursField: string, dateField: string): void {
        if (this.data.component !== 'curation' && this.data.component !== 'quiz-analyze') {
            this.advancedFilterManage.handleDateChange(this.formFilterDate, hoursField, dateField);
        }
    }
}
