import {Injectable} from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class LocalstorageService {
    private readonly SECRET_KEY: string = "OMOTOR_2023";

    setItem(key: string, data: string) {
        let dataEncrypted = this.encryptToken(data);
        localStorage.setItem(key, dataEncrypted);
    }

    getItem(key: string): string {
        let data = localStorage.getItem(key) || '';
        let dataDecrypted = (data != '') ? this.decryptToken(data) : '';

        return dataDecrypted;
    }

    removeItem(key: string) {
        localStorage.removeItem(key);
    }

    private encryptToken(token: string): string {
        const encrypted = CryptoJS.AES.encrypt(token, this.SECRET_KEY);
        return encrypted.toString();
    }

    private decryptToken(encryptedToken: string): string {
        const decrypted = CryptoJS.AES.decrypt(encryptedToken, this.SECRET_KEY);
        return decrypted.toString(CryptoJS.enc.Utf8);
    }
}
