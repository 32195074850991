<mat-form-field class="input-sm">
    <mat-label>{{label | translate}}</mat-label>
    <input matInput appDateFormatter
           [formControl]="field" [min]="min" [max]="max"
           (dateChange)="selectedDate($event)" (input)="onInputChange($event)"
           [matDatepicker]="picker" [placeholder]="placeholder | translate">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <button mat-icon-button matSuffix *ngIf="field.value && field.enabled" (click)="resetField()">
        <mat-icon>clear</mat-icon>
    </button>
    <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
