import {AbstractControl, FormGroup, Validators} from "@angular/forms";
import {Injectable} from "@angular/core";
import {MotorMessageService} from "../../../service/motor-message.service";
import {DateUtils} from "../../utils/date-utils";

@Injectable({
    providedIn: 'root'
})

export class AdvancedFilterManageService {
    constructor(
        private motorMessage: MotorMessageService,
        private dateUtils: DateUtils,
    ) {
    }

    public clearValidators(...controls: AbstractControl[]): void {
        controls.forEach(control => {
            control?.clearValidators();
            control?.updateValueAndValidity();
        });
    }

    public handleDateChange(formFilterDate: FormGroup, hoursField: string, dateField: string): void {
        if (formFilterDate.get(hoursField)?.value || formFilterDate.get(dateField)?.value) {
            formFilterDate.get(dateField)?.setValidators(Validators.required);
            formFilterDate.get(hoursField)?.setValidators(Validators.required);
        } else {
            formFilterDate.get(dateField)?.clearValidators();
            formFilterDate.get(hoursField)?.clearValidators();
        }
        formFilterDate.get(dateField)?.updateValueAndValidity();
        formFilterDate.get(hoursField)?.updateValueAndValidity();
    }

    public filterValid(formFilterDate: FormGroup): boolean {
        const {dateStartOnly, dateEndOnly, hoursStart, hoursEnd} = formFilterDate?.value;
        if ((!!dateStartOnly !== !!hoursStart) || (!!dateEndOnly !== !!hoursEnd) || !formFilterDate.valid) {
            this.motorMessage.openSnackBar('labels.necessaryFillDateAndTime');
            formFilterDate.markAllAsTouched();
            return false;
        }

        let formattedDateStartOnly, formattedDateEndOnly;
        if (dateStartOnly) formattedDateStartOnly = this.dateUtils.convertDateTimeToTimezone(dateStartOnly, hoursStart);
        if (dateEndOnly) formattedDateEndOnly = this.dateUtils.convertDateTimeToTimezone(dateEndOnly, hoursEnd);

        if ((formattedDateStartOnly >= formattedDateEndOnly) && formattedDateEndOnly) {
            this.motorMessage.openSnackBar('labels.endBeforeStartError');
            formFilterDate.markAllAsTouched();
            return false;
        }
        return true;
    }

    public adjustingDate(formFilterDate: FormGroup, recordingsFilter: FormGroup): Promise<void> {
        return new Promise(resolve => {
            const filterDate = formFilterDate.value;
            recordingsFilter.get('dateStart')?.setValue(formFilterDate.get('dateStartOnly')?.value ?
                this.dateUtils.convertDateTimeToTimezone(filterDate.dateStartOnly, filterDate.hoursStart) : '');
            recordingsFilter.get('dateEnd')?.setValue(formFilterDate.get('dateEndOnly')?.value ?
                this.dateUtils.convertDateTimeToTimezone(filterDate.dateEndOnly, filterDate.hoursEnd) : '');
            resolve();
        });
    }
}
