import {TranslateService} from "@ngx-translate/core";
import {Injectable, OnDestroy} from '@angular/core';
import {DatePipe} from "@angular/common";
import {AuthenticationService} from 'src/app/pages/authentication/authentication.service';
import {Subscription} from "rxjs";
import moment from "moment";
import 'moment-timezone';

@Injectable({
    providedIn: 'root'
})
export class DateUtils implements OnDestroy {
    private dateWithFormat: string = '';
    private userTimezone: string = '';
    private timezoneSub: Subscription;
    private dateTime: string = '';

    constructor(
        private authService: AuthenticationService,
        private translate: TranslateService,
        private datePipe: DatePipe,
    ) {
        this.timezoneSub = this.authService.getCurrentUserObservable()
            .subscribe(user => this.userTimezone = user.timezone!.timezone!);
    }

    ngOnDestroy(): void {
        if (this.timezoneSub) {
            this.timezoneSub.unsubscribe();
        }
    }

    public getToday(): string {
        const time = moment().tz(this.userTimezone);
        return moment.tz(time.format('YYYY-MM-DD 00:00:00'), this.userTimezone).toISOString();
    };

    public formatDateAndTime(date: Date, time: string): string {
        let dt = moment.tz(date, this.userTimezone);
        const [hours, minutes] = time.split(':').map(Number);
        dt = dt.set({hour: hours, minute: minutes, second: 0, millisecond: 0});
        return dt.isValid() ? dt.toISOString() : 'Invalid Date';
    }

    public unformatDateAndTime(isoDate: string): { date: string, hours: string } {
        const dt = moment(isoDate).tz(this.userTimezone);
        const date = dt.format('YYYY-MM-DD 00:00:00');
        const hours = dt.format('HH:mm');
        return {date: this.convertDateToTimezone(date), hours};
    }

    public formatDateAndTimeToSpecificFormat(date: any, time?: any, dateFormat?: string, timeFormat?: string): any {
        if (date && dateFormat) {
            this.dateWithFormat = moment(date).tz(this.userTimezone).format(this.translate.instant(dateFormat))
                ?? 'Invalid Date';
        }
        if (time && timeFormat) {
            this.dateTime = moment(time).tz(this.userTimezone).format(this.translate.instant(timeFormat))
                ?? 'Invalid Date';
        }
        return {dateWithFormat: this.dateWithFormat, dateTime: this.dateTime};
    }

    public convertDateToTimezone(date: string, timezone?: string): string {
        let formattedDate: string;
        if (new Date(date).getHours()) {
            formattedDate = `${this.datePipe.transform(date, 'yyyy-MM-dd', 'UTC')} 00:00:00`;
        } else {
            formattedDate = `${this.datePipe.transform(date, 'yyyy-MM-dd')} 00:00:00`;
        }

        return this.convertToISO(formattedDate, timezone).toISOString();
    }

    public convertDateTimeToTimezone(date: string, time: string, timezone?: string): string {
        const formattedDate = `${this.datePipe.transform(date, 'yyyy-MM-dd', 'UTC')} ${time}:00`;
        return this.convertToISO(formattedDate, timezone).toISOString();
    }

    public convertToRFC2822(dateString: string, timezone?: string): string {
        const [day, month, year] = dateString.split('/').map(Number);
        const date = `${moment({day, month: month - 1, year}).format('YYYY-MM-DD')} 00:00:00`;
        const dateInTimezone = this.convertToISO(date, timezone);
        return dateInTimezone.isValid() ? dateInTimezone.toISOString() : 'Invalid Date';
    }

    public convertToISO(dateWithTime: string, timezone?: string): moment.Moment {
        return moment(moment.tz(dateWithTime, timezone || this.userTimezone).format());
    }
}
