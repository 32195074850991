import {Directive, ElementRef, HostListener} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
    selector: '[numbersOnly]',
    standalone: false
})
export class NumbersOnlyDirective {
    private regex: RegExp = new RegExp(/^[0-9]+$/g);
    private specialKeys: Array<string> = [
        'Backspace', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown', 'Delete'
    ];

    constructor(private el: ElementRef, private control: NgControl) {
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
        if (this.specialKeys.indexOf(event.key) !== -1 || event.ctrlKey || event.metaKey) {
            return;
        }
        const current: string = this.el.nativeElement.value;
        const next: string = current.concat(event.key);

        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent): void {
        const clipboardData = event.clipboardData || window['clipboardData'];
        this.control.control?.setValue(clipboardData.getData('text').replace(/[^0-9]/g, ''));
        event.preventDefault();
    }
}
