import {NativeDateAdapter} from "@angular/material/core";
import {DatePipe} from "@angular/common";
import {Injectable} from "@angular/core";
import {AuthenticationService} from "../../pages/authentication/authentication.service";
import moment from "moment";
import {take} from "rxjs";

@Injectable({providedIn: "root"})
export class CustomDateAdapter extends NativeDateAdapter {
    userTimezone: string = 'America/Sao_Paulo';

    constructor(private authService: AuthenticationService, private datePipe: DatePipe,) {
        super('pt-BR');
        this.authService.getCurrentUserObservable().pipe(take(1)).subscribe(user => {
            this.userTimezone = user.timezone?.timezone ?? this.userTimezone;
        });
    }

    override format(date: Date, displayFormat: any): string {
        const formattedDate = `${this.datePipe.transform(date, 'yyyy-MM-dd', 'UTC')} 00:00:00`;
        return moment(moment.tz(formattedDate, this.userTimezone)).format('DD/MM/YYYY');
    }
}
