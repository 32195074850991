import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DateUtils} from "../../utils/date-utils";

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    standalone: false
})
export class DatePickerComponent {
    @Output() valueChanged = new EventEmitter<string>();
    @Input() placeholder: string = '';
    @Input() timezone: string = '';
    @Input() label: string = '';
    @Input() min: string = '';
    @Input() max: string = '';
    @Input() field: any;

    constructor(private dateUtils: DateUtils) {
    }

    protected selectedDate(event: any): void {
        this.valueChanged.emit(this.dateUtils.convertDateToTimezone(event.value, this.timezone));
        this.field.setValue(this.dateUtils.convertDateToTimezone(event.value, this.timezone));
    }

    protected onInputChange(event: any): void {
        const input = event.target.value;
        if (input.length === 10) {
            this.valueChanged.emit(this.dateUtils.convertToRFC2822(input, this.timezone));
            this.field.setValue(this.dateUtils.convertToRFC2822(input, this.timezone));
        }
        if (!input.length) {
            this.resetField();
        }
    }

    protected resetField(): void {
        this.valueChanged.emit();
        this.field.reset();
    }
}
