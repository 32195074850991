import {ILabelInterface} from "../../models/common.model";

export const AnswerStatusLabels: ILabelInterface[] = [
    {value: 'INCORRECT', label: 'labels.incorrectAnswer'},
    {value: 'INVALID', label: 'labels.invalidAnswer'},
    {value: 'VALID', label: 'labels.validAnswer'},
]

export const ApprovalStatusLabels: ILabelInterface[] = [
    {value: 0, label: 'labels.pending'},
    {value: 1, label: 'labels.deny'},
    {value: 2, label: 'labels.approved'},
    {value: 3, label: 'labels.automatic_approved'},
]

export const TrainingStatusLabels: ILabelInterface[] = [
    {value: 0, label: 'labels.pending'},
    {value: 1, label: 'labels.discarded'},
    {value: 2, label: 'labels.treined'},
]

export const CurationStatusLabels: ILabelInterface[] = [
    {value: 'PENDING', label: 'labels.pending'},
    {value: 'DISCARDED', label: 'labels.discarded'},
    {value: 'TRAINED', label: 'labels.treined'},
]
