import {HttpClient} from "@angular/common/http";
import {Injectable, OnDestroy} from "@angular/core";
import {Observable, Subscription} from "rxjs";

import {AuthenticationService} from "../pages/authentication/authentication.service";
import {environment} from "../../environments/environment";

const apiUrl: string = environment.endpointAPI;

@Injectable({
    providedIn: 'root'
})
export class QuizService implements OnDestroy {
    private endpointUrl: string = `${apiUrl}/v1/quiz`;
    private workspaceId: number = 0;
    workspaceSub: Subscription;

    constructor(
        private authService: AuthenticationService,
        private http: HttpClient,
    ) {
        this.workspaceSub = this.authService.getCurrentUserObservable()
            .subscribe(user => this.workspaceId = user.workspaceId!);
    }

    ngOnDestroy(): void {
        if (this.workspaceSub) {
            this.workspaceSub.unsubscribe();
        }
    }

    getQuiz(): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}`);
    }

    findQuizById(quizId: any): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/${quizId}`);
    }

    createQuiz(quizRequest: any): Observable<any> {
        return this.http.post<any>(`${this.endpointUrl}`, {...quizRequest, workspace: {id: this.workspaceId}});
    }

    updateQuiz(quizRequest: any): Observable<any> {
        return this.http.put<any>(`${this.endpointUrl}`, {...quizRequest, workspace: {id: this.workspaceId}});
    }

    deleteQuiz(quizId: number): Observable<any> {
        return this.http.delete(`${this.endpointUrl}/${quizId}`);
    }

    getQuestions(quizId: number): Observable<any> {
        return this.http.get(`${this.endpointUrl}/${quizId}/question`);
    }

    createQuestion(questionRequest: any): Observable<any> {
        return this.http.post<any>(`${this.endpointUrl}/question`, questionRequest);
    }

    updateQuestion(questionRequest: any): Observable<any> {
        return this.http.put<any>(`${this.endpointUrl}/question`, questionRequest);
    }

    deleteQuestion(questionId: number): Observable<any> {
        return this.http.delete(`${this.endpointUrl}/question/${questionId}`);
    }

    findAllRecorded(filter: any): Observable<any> {
        return this.http.post(`${this.endpointUrl}/recorded`, filter);
    }

    findActives(): Observable<any> {
        return this.http.get(`${this.endpointUrl}/actives`);
    }

    generateReport(request: any): Observable<ArrayBuffer> {
        return this.http.post(`${this.endpointUrl}/recorded/report`, {workspaceId: this.workspaceId, ...request}, {responseType: "arraybuffer"});
    }
}
