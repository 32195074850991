import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {Directive, ElementRef, forwardRef, HostListener} from '@angular/core';

@Directive({
    selector: '[appTimeFormatter]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => TimeFormatterDirective),
            multi: true
        }
    ],
    standalone: false
})
export class TimeFormatterDirective implements Validator {

    constructor(private el: ElementRef) {
    }

    @HostListener('input', ['$event'])
    onInput(event: Event): void {
        const input = event.target as HTMLInputElement;
        let value = input.value;
        value = value.replace(/[^0-9:]/g, '');

        // Split the string into hours and minutes
        const parts = value.split(':');
        let hour = parts[0].slice(0, 2);
        let minute = (parts.length > 1 ? parts[1] : '').slice(0, 2);

        // Correct hours and minutes if necessary
        hour = (hour.length === 2 && parseInt(hour) > 23) ? '23' : hour;
        minute = (minute.length === 2 && parseInt(minute) > 59) ? '59' : minute;

        value = hour + (minute ? ':' + minute : '');
        if (input.value !== value) {
            input.value = value;
            input.dispatchEvent(new Event('input'));
        }
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
        const key = event.key;
        const value = this.el.nativeElement.value;

        if (key === 'Backspace' || key === 'Tab' || key === 'Delete' || key === 'ArrowLeft' || key === 'ArrowRight') {
            return;
        }

        // Prevents the entry of non-numeric characters except colon
        if (key === ':' && !value.includes(':')) {
            event.preventDefault();
        } else if (!/[0-9]/.test(key)) {
            event.preventDefault();
        } else if (value.length === 2 && !value.includes(':')) {
            this.el.nativeElement.value = value + ':';
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        const value = control.value;
        if (!value) {
            return null;
        }

        // Check if the value has exactly 5 characters (HH:mm)
        if (value.length !== 5) {
            return {lengthError: 'Time must be in HH:mm format'};
        }

        const parts = value.split(':');
        const hour = parseInt(parts[0], 10);
        const minute = parseInt(parts[1], 10);
        if (hour < 0 || hour > 23 || minute < 0 || minute > 59) {
            return {timeError: 'Invalid time format'};
        }

        return null;
    }
}
