import {HttpClient} from "@angular/common/http";
import {Injectable, OnDestroy} from "@angular/core";
import {Observable, Subscription} from "rxjs";
import {AuthenticationService} from "../pages/authentication/authentication.service";
import {environment} from "../../environments/environment";

const apiUrl: string = environment.endpointAPI;

@Injectable({
    providedIn: 'root'
})
export class GroupingService implements OnDestroy {
    private endpointUrl: string = `${apiUrl}/v1/grouping`;
    private workspaceId: number = 0;
    workspaceSub: Subscription;

    constructor(
        private authService: AuthenticationService,
        private http: HttpClient,
    ) {
        this.workspaceSub = this.authService.getCurrentUserObservable()
            .subscribe(user => this.workspaceId = user.workspaceId!);
    }

    ngOnDestroy(): void {
        if (this.workspaceSub) {
            this.workspaceSub.unsubscribe();
        }
    }

    getAllGrouping(): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/${this.workspaceId}/1`);
    }
}
