import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
    selector: '[appPhoneFormat]',
    standalone: false
})
export class PhoneDirective {
    private regex: RegExp = new RegExp(/^[0-9()+-]+$/g);
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown', 'Delete'];

    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }

        if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
            return;
        }

        const current: string = this.el.nativeElement.value;
        const next: string = current.concat(event.key);

        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent): void {
        const clipboardData = event.clipboardData || window['clipboardData'];
        const pastedText = clipboardData.getData('text');

        if (pastedText && !pastedText.match(this.regex)) {
            event.preventDefault();
        }
    }
}
