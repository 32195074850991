import {HttpClient} from "@angular/common/http";
import {Injectable, OnDestroy} from "@angular/core";
import {Observable, Subscription} from "rxjs";
import {AuthenticationService} from "../pages/authentication/authentication.service";
import {environment} from "../../environments/environment";

const apiUrl: string = environment.endpointAPI;

@Injectable({
    providedIn: 'root'
})
export class MonesConversationService implements OnDestroy {
    private endpointUrl: string = `${apiUrl}/v1/conversation`;
    private workspaceId: number = 0;
    workspaceSub: Subscription;

    constructor(
        private authService: AuthenticationService,
        private http: HttpClient,
    ) {
        this.workspaceSub = this.authService.getCurrentUserObservable()
            .subscribe(user => this.workspaceId = user.workspaceId!);
    }

    ngOnDestroy(): void {
        if (this.workspaceSub) {
            this.workspaceSub.unsubscribe();
        }
    }

    getAllConversation(): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/all/${this.workspaceId}`);
    }

    getAllConversationSchedulePending(): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/schedule/pending`);
    }

    getAllConversationScheduleReach(id: number): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/schedule/reach/${id}`);
    }

    approveConversationSchedule(id: number): Observable<any> {
        return this.http.post<any>(`${this.endpointUrl}/schedule/approve/${id}`, {});
    }

    denyConversationSchedule(id: number): Observable<any> {
        return this.http.post<any>(`${this.endpointUrl}/schedule/deny/${id}`, {});
    }

    getAllConversationCombo(): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/combo/`);
    }

    getScheduleId(scheduleId: number): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/schedule/id/${scheduleId}`);
    }

    getSchedule(conversationId: number): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/schedule/${conversationId}`);
    }

    getSchedulePagination(conversationId: number, request: any): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/schedule/${conversationId}/paginated`, {params: request});
    }

    generatingReach(scheduleRequest: any): Observable<any> {
        return this.http.post<any>(`${this.endpointUrl}/schedule/reach/create/`, scheduleRequest);
    }

    getReach(scheduleId: any): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/schedule/reach/${scheduleId}`);
    }

    getConversation(conversationId: number): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/${conversationId}/${this.workspaceId}`);
    }

    createSchedule(scheduleRequest: any): Observable<any> {
        return this.http.post<any>(`${this.endpointUrl}/schedule`, {
            ...scheduleRequest,
            monesConversation: {...scheduleRequest.monesConversation, workspace: {id: this.workspaceId}}
        });
    }

    getConversations(): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/${this.workspaceId}`);
    }

    findById(id: number): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/${id}/${this.workspaceId}`);
    }

    startOrPauseConversation(conversationId: number, action: number): Observable<any> {
        return this.http.put<any>(`${this.endpointUrl}/status/${this.workspaceId}/${conversationId}/${action}`, {});
    }

    deleteConversation(conversationId: number): Observable<any> {
        return this.http.delete<any>(`${this.endpointUrl}/${this.workspaceId}/${conversationId}`);
    }

    saveConversation(request: any): Observable<any> {
        return this.http.post<any>(`${this.endpointUrl}`, {...request, workspace: {id: this.workspaceId}});
    }

    updateConversation(request: any): Observable<any> {
        return this.http.put<any>(`${this.endpointUrl}`, {...request, workspace: {id: this.workspaceId}});
    }

    createBotFacebook(request: any): Observable<any> {
        return this.http.post<any>(`${this.endpointUrl}/create/bot/facebook`, request);
    }

    createBotInstagram(request: any): Observable<any> {
        return this.http.post<any>(`${this.endpointUrl}/create/bot/instagram`, request);
    }

    toggleMessageEvent(id?: number): Observable<any> {
        return this.http.put<any>(`${this.endpointUrl}/message-event/${id}/toggle`, {});
    }
}
