import {MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorIntl, MatPaginatorModule} from "@angular/material/paginator";
import {provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {DateAdapter, MatNativeDateModule} from "@angular/material/core";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatGridListModule} from '@angular/material/grid-list';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDividerModule} from '@angular/material/divider';
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatToolbarModule} from "@angular/material/toolbar";
import {CommonModule, DecimalPipe} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {MatSelectModule} from "@angular/material/select";
import {MatDialogModule} from "@angular/material/dialog";
import {MatTableModule} from "@angular/material/table";
import {MatBadgeModule} from "@angular/material/badge";
import {MatChipsModule} from '@angular/material/chips';
import {MatRadioModule} from "@angular/material/radio";
import {MatInputModule} from "@angular/material/input";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatTabsModule} from "@angular/material/tabs";
import {MatMenuModule} from "@angular/material/menu";
import {MatListModule} from "@angular/material/list";
import {MatCardModule} from "@angular/material/card";
import {MatIconModule} from "@angular/material/icon";
import {MatSortModule} from "@angular/material/sort";
import {NgModule} from "@angular/core";
import {DialogAdvancedFilterComponent} from "./component/dialog-advanced-filter/dialog-advanced-filter.component";
import {ViewTemplateDialogComponent} from "./component/view-template-dialog/view-template-dialog.component";
import {MatPaginatorIntlTranslate} from "./constants/mat-paginator-intl-translate.constants";
import {AutocompleteComponent} from "./component/autocomplete/autocomplete.component";
import {AlertDialogComponent} from './component/dialog-alert/alert-dialog.component';
import {DatePickerComponent} from './component/date-picker/date-picker.component';
import {DateFormatterDirective} from "./directives/date-formatter.directive";
import {TimeFormatterDirective} from "./directives/time-formatter.directive";
import {NumbersOnlyDirective} from "./directives/numbers-only-directive";
import {WebChatComponent} from './component/webchat/web-chat.component';
import {CustomDateAdapter} from "./date-adapter/custom-date-adapter";
import {UserTimezoneDatePipe} from "./pipes/user-timezone-date.pipe";
import {MaxlengthDirective} from "./directives/maxlength-directive";
import {ChatComponent} from "../pages/webchat/chat/chat.component";
import {PhoneDirective} from "./directives/phone-directive";
import {NgxMaskModule} from "ngx-mask";
import {CaseFormatterDirective} from "./directives/case-formatter.directive";
import {WhatsappScreenComponent} from "./component/whatsapp-screen/whatsapp-screen.component";

@NgModule({
    declarations: [
        DialogAdvancedFilterComponent,
        ViewTemplateDialogComponent,
        TimeFormatterDirective,
        DateFormatterDirective,
        CaseFormatterDirective,
        AutocompleteComponent,
        AlertDialogComponent,
        NumbersOnlyDirective,
        UserTimezoneDatePipe,
        DatePickerComponent,
        MaxlengthDirective,
        WebChatComponent,
        PhoneDirective,
        ChatComponent,
        WhatsappScreenComponent,
    ],
    imports: [
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        MatProgressBarModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatPaginatorModule,
        MatExpansionModule,
        MatSnackBarModule,
        MatGridListModule,
        MatCheckboxModule,
        MatToolbarModule,
        MatDividerModule,
        MatTooltipModule,
        MatSelectModule,
        MatButtonModule,
        TranslateModule,
        MatDialogModule,
        MatBadgeModule,
        MatChipsModule,
        MatInputModule,
        MatTableModule,
        MatRadioModule,
        DragDropModule,
        MatTabsModule,
        MatMenuModule,
        MatIconModule,
        MatSortModule,
        MatCardModule,
        MatListModule,
        CommonModule,
        FormsModule,
        NgxMaskModule.forRoot()
    ],
    exports: [
        MatProgressSpinnerModule,
        TimeFormatterDirective,
        DateFormatterDirective,
        MatAutocompleteModule,
        AutocompleteComponent,
        UserTimezoneDatePipe,
        MatProgressBarModule,
        MatSlideToggleModule,
        NumbersOnlyDirective,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        DatePickerComponent,
        MatFormFieldModule,
        MatPaginatorModule,
        MaxlengthDirective,
        MatSnackBarModule,
        MatCheckboxModule,
        MatGridListModule,
        MatToolbarModule,
        MatTooltipModule,
        WebChatComponent,
        MatDividerModule,
        MatButtonModule,
        MatDialogModule,
        MatSelectModule,
        TranslateModule,
        PhoneDirective,
        MatBadgeModule,
        MatChipsModule,
        MatInputModule,
        MatTableModule,
        MatRadioModule,
        DragDropModule,
        MatCardModule,
        MatIconModule,
        MatSortModule,
        MatMenuModule,
        MatTabsModule,
        MatListModule,
        ChatComponent,
        NgxMaskModule,
        CommonModule,
        FormsModule,
        CaseFormatterDirective,
        WhatsappScreenComponent,
    ],
    providers: [
        DecimalPipe,
        {provide: DateAdapter, useClass: CustomDateAdapter},
        {
            provide: MatPaginatorIntl,
            useFactory: (translate: TranslateService, decimalPipe: DecimalPipe) => {
                return new MatPaginatorIntlTranslate(translate, decimalPipe);
            },
            deps: [TranslateService, DecimalPipe]
        },
        {
            provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
            useValue: {pageSize: 100}
        },
        provideHttpClient(withInterceptorsFromDi())
    ]
})

export class SharedModule {
}
