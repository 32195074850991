import {Directive, HostListener, Input} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
    selector: '[appMaxlength]',
    standalone: false
})
export class MaxlengthDirective {
    @Input('appMaxlength') maxlength: string = '';
    @Input() min: string = '';
    @Input() max: string = '';

    constructor(private ngControl: NgControl) {
    }

    @HostListener('ngModelChange', ['$event'])
    onModelChange(newValue: any): void {
        const maxLength = Number(this.maxlength);
        const min = Number(this.min);
        const max = Number(!!this.max ? this.max : Math.pow(10, maxLength) - 1);
        if ((newValue ?? '').toString().length > maxLength || typeof newValue !== 'number') {
            newValue = newValue?.toString().slice(0, maxLength);
        }
        if (newValue < min) newValue = min;
        if (newValue > max) newValue = max;
        if (newValue !== this.ngControl.control!.value) {
            this.ngControl.valueAccessor!.writeValue(newValue);
            this.ngControl.control!.setValue(newValue, {emitEvent: false});
        }
    }
}
