import {animate, style, transition, trigger} from "@angular/animations";
import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-whatsapp-screen',
    templateUrl: './whatsapp-screen.component.html',
    styleUrl: './whatsapp-screen.component.scss',
    animations: [
        trigger("inOutPaneAnimation", [
            transition(":enter", [
                style({ opacity: 0, transform: "translateX(-100%)" }),
                animate("750ms ease-in-out", style({ opacity: 1, transform: "translateX(0)" }))
            ]),
            transition(":leave", [
                style({ opacity: 1, transform: "translateX(0)" }),
                animate("600ms ease-in-out", style({ opacity: 0, transform: "translateX(-100%)" }))
            ])
        ])
    ],
    standalone: false
})
export class WhatsappScreenComponent {
    @Input('previewSource') previewSource: string = '';
    @Input('template') actualTemplate: string = '';
    @Input('previewType') previewType: number = 0;

    constructor() {
    }
}
