<div class="iphone">
    <!-- Screenshot	 -->
    <div class="page screenshot">
        <div class="marvel-device nexus5">
            <div class="screen">
                <div class="screen-container">
                    <div class="chat">
                        <div class="chat-container">
                            <div class="user-bar">
                                <div class="back">
                                    <i class="zmdi zmdi-arrow-left"></i>
                                </div>
                                <div class="avatar">
                                    <img src="../../../../assets/images/omotor_logo.jpg" alt="Avatar">
                                </div>
                                <div class="name">
                                    <span>OMOTOR</span>
                                    <span class="status">online</span>
                                </div>
                                <div class="actions more">
                                    <i class="zmdi zmdi-more-vert"></i>
                                </div>
                                <div class="actions attachment">
                                    <i class="zmdi zmdi-attachment-alt"></i>
                                </div>
                                <div class="actions">
                                    <i class="zmdi zmdi-phone"></i>
                                </div>
                            </div>
                            <div class="conversation">
                                <div style="padding-bottom: 90px" class="conversation-container custom-padding">
                                    <div class="message received" @inOutPaneAnimation *ngIf="actualTemplate != ''">
                                        <video *ngIf="previewSource?.length && previewType === 5"
                                               [src]="previewSource" class="content-preview">
                                            <track kind="metadata" label="User upload" src="">
                                        </video>
                                        <img *ngIf="previewSource?.length && previewType === 1"
                                             class="content-preview mb-1" [src]="previewSource" alt="User upload">
                                        <span id="random">{{actualTemplate}}</span>
                                        <span class="metadata"><span class="time"></span></span>
                                    </div>
                                </div>
                                <form class="conversation-compose">
                                    <div class="emoji">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" id="smiley"
                                             x="3147" y="3209">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M9.153 11.603c.795 0 1.44-.88 1.44-1.962s-.645-1.96-1.44-1.96c-.795 0-1.44.88-1.44 1.96s.645 1.965 1.44 1.965zM5.95 12.965c-.027-.307-.132 5.218 6.062 5.55 6.066-.25 6.066-5.55 6.066-5.55-6.078 1.416-12.13 0-12.13 0zm11.362 1.108s-.67 1.96-5.05 1.96c-3.506 0-5.39-1.165-5.608-1.96 0 0 5.912 1.055 10.658 0zM11.804 1.01C5.61 1.01.978 6.034.978 12.23s4.826 10.76 11.02 10.76S23.02 18.424 23.02 12.23c0-6.197-5.02-11.22-11.216-11.22zM12 21.355c-5.273 0-9.38-3.886-9.38-9.16 0-5.272 3.94-9.547 9.214-9.547a9.548 9.548 0 0 1 9.548 9.548c0 5.272-4.11 9.16-9.382 9.16zm3.108-9.75c.795 0 1.44-.88 1.44-1.963s-.645-1.96-1.44-1.96c-.795 0-1.44.878-1.44 1.96s.645 1.963 1.44 1.963z"
                                                  fill="#7d8489"/>
                                        </svg>
                                    </div>
                                    <input class="input-msg" name="input" placeholder="Mensagem" autocomplete="off"
                                           autofocus readonly/>
                                    <div class="photo">
                                        <i class="zmdi zmdi-camera"></i>
                                    </div>
                                    <button class="send">
                                        <div class="circle">
                                            <mat-icon>send</mat-icon>
                                        </div>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Mockup -->
    <img class="frame" src="../../../../assets/images/iphone-frame.png" alt="iphone frame"/>
</div>
