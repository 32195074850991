import {IBaseModel, ILabelInterface} from "../../models/common.model";

export const typeDialogActionId: ILabelInterface[] = [
    {value: 1, label: 'labels.user'},
    {value: 2, label: 'labels.mones'},
    {value: 3, label: 'labels.attendant'},
];

export const transferToHumanTypes: IBaseModel[] = [
    {id: 0, name: 'labels.none'},
    {id: 1, name: 'labels.mones'},
    {id: 2, name: 'labels.serviceNow'},
    {id: 3, name: 'labels.voll'},
    {id: 4, name: 'labels.omotorHelpdesk'},
    {id: 5, name: 'labels.genesysWittel'},
    {id: 6, name: 'labels.genesysLikeWater'},
];

export const dialogActionStatus: IBaseModel[] = [
    {id: 1, name: 'labels.inactivated'},
    {id: 2, name: 'labels.activated'},
];
export const dialogActionMenuType: IBaseModel[] = [
    {id: 0, name: 'labels.removeMenuOptions'},
    {id: 1, name: 'labels.textOptions'},
    {id: 2, name: 'labels.buttonOptions'},
];
