import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';

declare var createChat: any;

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.css'],
    standalone: false
})
export class ChatComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
        this.loadScripts();
    }

    loadScripts() {
        $.getScript('assets/js/jquery-3.3.1.min.js');

        $.getScript('assets/js/botchat.js');

        $.getScript('assets/js/botinit.js', () => {
            if (!localStorage.getItem('userIdSla')) {
                const id = Math.random().toString(36).substr(2, 17);
                localStorage.setItem('userIdSla', id);
            }

            createChat(
                'axTp42lcD5I.XkUG6baYR5L8rfaFKcOxqIsrR7pBB2RJD39XcSqpR9U',
                localStorage.getItem('userIdSla'),
                '',
                'prod'
            );
        });
    }
}
