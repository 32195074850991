import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {environment} from "../../environments/environment";

const apiUrl: string = environment.endpointAPI;

@Injectable({
    providedIn: 'root'
})
export class FileService {
    private endpointUrl: string = `${apiUrl}/v1`;

    constructor(
        private http: HttpClient
    ) {
    }

    uploadFile(file: any): Observable<any> {
        return this.http.post<any>(`${this.endpointUrl}/file/upload/byte`, file);
    }

    importContact(file: any): Observable<any> {
        return this.http.post<any>(`${this.endpointUrl}/file/import/contact/item`, file);
    }

    importEntityValue(file: any): Observable<any> {
        return this.http.post<any>(`${this.endpointUrl}/file/import/entity/value`, file);
    }

    exportFile(exportRequest: any): Observable<any> {
        return this.http.post<any>(`${this.endpointUrl}/assign/url/`, exportRequest);
    }

    uploadMultiPartFile(file: any): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        return this.http.post<any>(`${this.endpointUrl}/file/upload/multipartfile`, file, {headers});
    }

    generateCsvReport(contactsId: number, additionalColumn: number): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/contact/report/${contactsId}/${additionalColumn}`,
            {responseType: 'blob' as 'json'});
    }

    generateCsvReportAsync(contactsId: number, additionalColumn: number, email: string): Observable<any> {
        return this.http.get<any>(`${this.endpointUrl}/contact/report/async/${contactsId}/${window.encodeURIComponent(email)}/${additionalColumn}`,
            {responseType: 'blob' as 'json'});
    }
}
