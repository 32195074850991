<mat-form-field class="input-sm" (focusout)="focusout()">
    <mat-label>{{label | translate}}</mat-label>
    <input type="text" [formControl]="showField" matInput [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" #autocompleteElement (optionSelected)="selectItem($event.option.value)">
        <mat-option *ngFor="let item of filteredItems | async" [value]="item">{{item[attribute]!}}</mat-option>
    </mat-autocomplete>
    <mat-icon *ngIf="!showField.value" matIconSuffix>arrow_drop_down</mat-icon>
    <mat-icon matIconSuffix class="icon-actions primary" *ngIf="showField.value && field.enabled" (click)="resetField()">
        clear
    </mat-icon>
</mat-form-field>
