import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';
import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
    selector: '[appDateFormatter]',
    standalone: false
})
export class DateFormatterDirective implements Validator {
    constructor(private el: ElementRef) {
    }

    @HostListener('input', ['$event']) onInput(event: Event): void {
        const input = event.target as HTMLInputElement;
        let value = input.value;
        value = value.replace(/[^0-9/]/g, '');

        const parts = value.split('/');
        let day = parts[0].slice(0, 2);
        let month = (parts.length > 1 ? parts[1] : '').slice(0, 2);
        let year = (parts.length > 2 ? parts[2] : '').slice(0, 4);

        day = (day.length === 2 && parseInt(day) > 31) ? '31' : day;
        month = (month.length === 2 && parseInt(month) > 12) ? '12' : month;

        value = day + (month ? '/' + month : '') + (year ? '/' + year : '');

        if (input.value !== value) {
            input.value = value;
            input.dispatchEvent(new Event('input'));
        }
    }

    @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent): void {
        const key = event.key;
        const value = this.el.nativeElement.value;

        if (key === 'Backspace' || key === 'Tab' || key === 'Delete' || key === 'ArrowLeft' || key === 'ArrowRight') {
            return;
        }

        if (key === '/' && (value.match(/\//g) || []).length < 2) {
            return;
        } else if (!/[0-9]/.test(key)) {
            event.preventDefault();
        } else if (value.length === 2 || value.length === 5) {
            this.el.nativeElement.value = value + '/';
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        const value = control.value;
        if (!value) {
            return null;
        }

        if (value.length !== 10) {
            return {lengthError: 'Date must be in DD/MM/YYYY format'};
        }

        const parts = value.split('/');
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10);
        const year = parseInt(parts[2], 10);

        if (day < 1 || day > 31 || month < 1 || month > 12 || year < 1000 || year > 9999) {
            return {dateError: 'Invalid date format'};
        }

        return null;
    }
}
